function CorporateCouponTemplates () {
  function AddServicesToTemplate () {
    $('#add_services_to_template').on('click', function () {
      $(this).attr('disabled', true)
      $(this).html('Please wait...')

      $.ajax({
        type: 'POST',
        dataType: 'script',
        url: '/corporate_coupon_templates/associate_services',
        data: {
          linked_service_ids: $('#corporate_coupon_template_shop_ppd_detail_ids').val()
        }
      })
    })
  }

  function ToggleFields () {
    function hideField (classSelector) {
      const selector = `${classSelector} :input`

      $(classSelector).addClass('hide')
      $(selector).removeAttr('required')
    }

    function showField (classSelector) {
      const selector = `${classSelector} :input`

      $(classSelector).removeClass('hide')
      $(selector).prop('required', true)
    }

    function checkTemplateTypeFields (selectedType) {
      checkUsageLimit()

      if (selectedType === 'cashback') {
        showField('.cashback_coupon_group')
      } else {
        hideField('.cashback_coupon_group')
      }

      if (selectedType === 'normal' || selectedType === 'cashback') {
        showField('.credit_type')
      } else {
        hideField('.credit_type')
        hideField('.amount')
        hideField('.percentage')
        hideField('.maximum_discount')
      }
    }

    function checkCreditTypeFields (selectedType) {
      checkUsageLimit()

      if (selectedType === 'amount') {
        showField('.amount')
        hideField('.percentage')
        hideField('.maximum_discount')
      } else if (selectedType === 'percent') {
        hideField('.amount')
        showField('.percentage')
        showField('.maximum_discount')
      } else {
        hideField('.amount')
        hideField('.percentage')
        hideField('.maximum_discount')
      }
    }

    function checkUsageLimit () {
      const templateTypeValue = $('#corporate_coupon_template_template_type').val()
      const creditTypeValue = $('#corporate_coupon_template_credit_type').val()

      if (templateTypeValue === 'normal' && creditTypeValue === 'percent') {
        showField('.usage_limit')
      } else {
        hideField('.usage_limit')
      }
    }

    function checkServicesTypeFields (selectedType) {
      if (selectedType === 'custom') {
        showField('.services_custom_selection')
      } else {
        hideField('.services_custom_selection')
      }
    }

    function checkOnloadFields () {
      const templateTypeValue = $('#corporate_coupon_template_template_type').val()
      checkTemplateTypeFields(templateTypeValue)
      const creditTypeValue = $('#corporate_coupon_template_credit_type').val()
      checkCreditTypeFields(creditTypeValue)
      const servicesTypeValue = $('#corporate_coupon_template_services_type').val()
      checkServicesTypeFields(servicesTypeValue)
    }

    $(document).on('change', '#corporate_coupon_template_template_type', function () {
      const selectedType = $(this).val()

      checkTemplateTypeFields(selectedType)
    })

    $(document).on('change', '#corporate_coupon_template_credit_type', function () {
      const selectedType = $(this).val()

      checkCreditTypeFields(selectedType)
    })

    $(document).on('change', '#corporate_coupon_template_services_type', function () {
      const selectedType = $(this).val()

      checkServicesTypeFields(selectedType)
    })

    checkOnloadFields()
  }

  if (window.location.pathname.includes('corporate_coupon_templates')) {
    AddServicesToTemplate()
    ToggleFields()
  }
}

export default CorporateCouponTemplates
